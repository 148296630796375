import React from "react";

function ProgressBar(props) {
  return (
    <svg width="50" height="50" className="chart-container">
      <circle
        cx="25"
        cy="25"
        r="22"
        fill={
          props.progress < 1
            ? "rgba(255, 157, 43, 0.18)"
            : "rgba(126, 184, 131, 0.2)"
        }
      />
      <circle
        cx="25"
        cy="25"
        r="22"
        className="front"
        stroke={props.progress < 1 ? "#FF9D2B" : "#7EB883"}
        strokeDasharray={2 * 3.14 * 22 * props.progress}
      />
      <g className="text">
        <text
          x="25"
          y="25"
          alignment-baseline="central"
          text-anchor="middle"
          id="percentage"
          fill={props.progress < 1 ? "#FF9D2B" : "#7EB883"}
        >
          {props.progress * 100}%
        </text>
      </g>
    </svg>
  );
}

export default ProgressBar;
