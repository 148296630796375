import React, { useState, Component } from "react";
import Approval from "assets/images/approval-active.svg";
import Search from "assets/images/search.svg";
import CardViewApproval from "./Component/CardViewApproval";
import API from "../services/index";

class ContentApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // content: {
      //   company_name: "",
      //   nib1: "",
      //   nib2: "",
      //   address: "",
      //   no_kemenkumham: "",
      //   direktur: "",
      //   komisaris: "",
      //   project: 0,
      // },
      content: [],
      reject: false,
      showApprove: false,
      showReject: false,
      company_modal: "",
    };
  }

  setShowApprove = (data) => {
    this.setState({
      showApprove: true,
      company_modal: data,
    });
  };

  setCloseApprove = () => {
    this.setState({
      showApprove: false,
    });
  };

  setShowReject = (data) => {
    this.setState({
      showReject: true,
      company_modal: data,
    });
  };

  setCloseReject = () => {
    this.setState({
      showReject: false,
    });
  };

  getContentAPI = () => {
    API.getContentApproval().then((result) => {
      this.setState({
        content: result,
      });
    });
  };

  componentDidMount() {
    this.getContentAPI();
  }
  render() {
    var count = Object.keys(this.state.content).length;
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="page-header">
            <img src={Approval} alt="" />
            <h5>Approval Perusahaan</h5>
          </div>
        </section>

        {/* Section Content */}
        <section className="content">
          <div className="content-main">
            <div className="wrapper-title">
              <div className="img-wrapper">
                <img src={Approval} alt="" />
              </div>
              <h5 className="content-title">Approval Perusahaan</h5>
            </div>

            <div className="wrapper-search">
              <img className="search-icon" src={Search} alt="" />
              <input
                type="text"
                placeholder="Search by ID, Username, HP, or Status... "
                className="search-input"
              />
            </div>

            <div className="wrapper-approval">
              <h5>
                <span>{count}</span> Approval Perusahaan
              </h5>
              {this.state.content.map((item) => {
                return (
                  <CardViewApproval
                    key={item.id}
                    company_name={item.name}
                    nib1="126382913241"
                    nib2="126382913242"
                    address=" Jl. Matraman Raya No.46, RT.12/RW.2, Kb. Manggis, Kec.
                    Matraman, Kota Jakarta Timur, Daerah Khusus Ibukota
                    Jakarta 13150"
                    no_kemenkumham="372233288238"
                    direktur="Angga Nugraha"
                    komisaris="Fithor Muhammad"
                    project="2"
                    showApproval={() => this.setShowApprove(item.name)}
                    showReject={() => this.setShowReject(item.name)}
                  />
                );
              })}
            </div>
          </div>
        </section>

        {/* Modal Agree */}
        {this.state.showApprove
          ? this.state.showApprove && (
              <div className="modal-card">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button
                        type="button"
                        class="close"
                        // onClick={() => setShowApprove(!showApprove)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <div className="wrapper-modal">
                        <div className="img-wrapper">
                          <img src={Approval} alt="aproval-img" />
                        </div>
                      </div>
                      <h5 class="title-modal" id="exampleModalLabel">
                        Approval Perusahaan
                      </h5>
                    </div>
                    <div class="body-modal">
                      Apakah Anda yakin ingin{" "}
                      <span className="status">menyetujui</span> request
                      Perusahaan{" "}
                      <span className="company-name">
                        {this.state.company_modal}
                      </span>
                      ?
                    </div>
                    <div class="footer-modal">
                      <button type="button" class="btn btn-primary">
                        Ya
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary"
                        onClick={() => this.setCloseApprove()}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          : ""}

        {/* Modal Reject */}
        {this.state.showReject
          ? this.state.showReject && (
              <div div className="modal-card">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button
                        type="button"
                        class="close"
                        // onClick={() => setShowReject(!showReject)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <div className="wrapper-modal">
                        <div className="img-wrapper">
                          <img src={Approval} alt="aproval-img" />
                        </div>
                      </div>
                      <h5 class="title-modal" id="exampleModalLabel">
                        Approval Perusahaan
                      </h5>
                    </div>
                    <div class="body-modal">
                      Apakah Anda yakin ingin{" "}
                      <span className="reject">menolak</span> request Perusahaan{" "}
                      <span className="company-name">
                        {this.state.company_modal}
                      </span>
                      ?
                    </div>
                    {this.state.reject && (
                      <div className="wrapper-text-area">
                        <textarea
                          name="reasons"
                          id=""
                          placeholder="Tuliskan Alasan"
                          className="text-area"
                        ></textarea>
                      </div>
                    )}
                    <div class="footer-modal">
                      {this.state.reject ? (
                        <>
                          <button type="button" class="btn btn-primary">
                            Kirim
                          </button>
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => {
                              // setReject(!reject);
                              // setShowReject(!showReject);
                            }}
                          >
                            Close
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            class="btn btn-primary"
                            // onClick={() => setReject(!reject)}
                          >
                            Ya
                          </button>
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => this.setCloseReject()}
                          >
                            Close
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          : ""}
      </div>
    );
  }
}

export default ContentApproval;
