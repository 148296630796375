import React, { useState, Component } from "react";
import ReportActive from "assets/images/report-active.svg";
import Search from "assets/images/search.svg";
import CardViewProject from "./Component/CardVIewProject";
import API from "../services/index";

class ContentViewProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: [],
    };
  }

  getContentProject = () => {
    API.getContentProject().then((result) => {
      this.setState({
        project: result,
      });
    });
  };

  componentDidMount() {
    this.getContentProject();
  }
  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="page-header">
            <img src={ReportActive} alt="" />
            <h5>View Report</h5>
          </div>
        </section>
        {/* Section Content */}
        <section className="content">
          <div className="content-main">
            <div className="wrapper-title">
              <div className="img-wrapper">
                <img src={ReportActive} alt="" />
              </div>
              <h5 className="content-title">View Report</h5>
            </div>

            <div className="wrapper-search">
              <img className="search-icon" src={Search} alt="" />
              <input
                type="text"
                placeholder="Search by ID, Username, HP, or Status... "
                className="search-input"
              />
            </div>
            {this.state.project.map((item) => {
              return (
                <CardViewProject
                  company_name={item.name}
                  address="Jl. Matraman Raya No.46, RT.12/RW.2, Kb. Manggis, Kec.
      Matraman, Kota Jakarta Timur, Daerah Khusus Ibukota
      Jakarta 13151"
                  luas_tanah="372233288231"
                  perusahaan="PT. Omind"
                  jumlah_unit="Fithor Muhammad"
                  nilai_proyek="2"
                  nama_pm=""
                />
              );
            })}
          </div>
        </section>
      </div>
    );
  }
}

export default ContentViewProject;
