import React, { useState, Component } from "react";
import Company from "assets/images/company.svg";
import Location from "assets/images/location.svg";
import Person from "assets/images/person.svg";
import Chart from "assets/images/chart.svg";
import Check from "assets/images/circle-check.svg";
import { isMobile } from "react-device-detect";

class CardViewApproval extends Component {
  render() {
    const {
      company_name,
      nib1,
      nib2,
      address,
      no_kemenkumham,
      direktur,
      komisaris,
      project,
    } = this.props;
    return (
      <div className="card-approval">
        <div className="company-details">
          <div className="img-wrapper">
            <img src={Company} alt="" />
          </div>
          <div className="wrapper-details">
            <p className="company-name">{company_name}</p>
            <div className={isMobile ? "nib-number" : "wrapper-detail-nomor"}>
              <div className="wrapper-nomor">
                <img src={Check} alt="" className="icon-check" />
                <h5 className="text-nomor">NIB : {nib1}</h5>
              </div>

              <div className="wrapper-nomor">
                <img src={Check} alt="" className="icon-check" />
                <h5 className="text-nomor">NIB : {nib2}</h5>
              </div>
            </div>
          </div>
          <div className="button-wrapper">
            <button
              type="button"
              className="button btn-approve"
              style={{ marginRight: "15px" }}
              onClick={this.props.showApproval}
            >
              Approve
            </button>

            <button
              type="button"
              className="button btn-reject"
              onClick={this.props.showReject}
            >
              Reject
            </button>
          </div>
        </div>
        <div className={isMobile ? "" : "detail-report"}>
          {/* Address */}
          <img src={Location} alt="" />
          <div className="address-detail">
            <p className="title">Alamat</p>
            <p className="detail-content">{address}</p>
          </div>

          {/* Kemenkumham Number */}
          <img src={Chart} alt="" />
          <div className="address-detail">
            <p className="title">No. Kemenkumham</p>
            <p className="detail-content">{no_kemenkumham}</p>
          </div>

          {/* Direktur */}
          <img src={Person} alt="" />
          <div className="address-detail">
            <p className="title">Direktur</p>
            <p className="detail-content">{direktur}</p>
          </div>

          {/* Komisaris */}
          <img src={Person} alt="" />
          <div className="address-detail">
            <p className="title">Komisaris</p>
            <p className="detail-content">{komisaris}</p>
          </div>

          {/* Project */}
          <img src={Person} alt="" />
          <div className="address-detail">
            <p className="title">Project</p>
            <p className="detail-content">{project}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default CardViewApproval;
