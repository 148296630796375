import { Tabs, usePanelState, useTabState } from "@bumaga/tabs";
import Approval from "assets/images/approval-active.svg";
import React from "react";
import {
  Perizinan,
  Pembangunan,
  Dashboard,
  Penjualan,
  Keuangan,
} from "./Dashboard";

function ContentDashboard(props) {
  const Tab = ({ children }) => {
    const { onClick, isActive } = useTabState();

    return (
      <button onClick={onClick} className={isActive && "active"}>
        {children}
      </button>
    );
  };

  const Panel = ({ children }) => {
    const isActive = usePanelState();

    return isActive ? (
      <div className="dashboard-wrapper">{children}</div>
    ) : null;
  };

  return (
    <div className="content-wrapper">
      {/* Section Content */}
      <section className="content">
        <div className="content-main">
          <div className="wrapper-title">
            <div className="img-wrapper">
              <img src={Approval} alt="" />
            </div>
            <h5 className="content-title">Approval Perusahaan</h5>
          </div>

          <Tabs>
            <div className="tab-bar">
              <Tab>Dashboard</Tab>
              <Tab>Perizinan</Tab>
              <Tab>Pembangunan</Tab>
              <Tab>Penjualan</Tab>
              <Tab>Keuangan</Tab>
            </div>

            <Panel>
              <Dashboard />
            </Panel>
            <Panel>
              <Perizinan />
            </Panel>
            <Panel>
              <Pembangunan />
            </Panel>
            <Panel>
              <Penjualan />
            </Panel>
            <Panel>
              <Keuangan />
            </Panel>
          </Tabs>
        </div>
      </section>
    </div>
  );
}

export default ContentDashboard;
