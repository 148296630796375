import Header from "parts/Header";
import Sidebar from "parts/Sidebar";
import React from "react";
import ContentViewProject from "parts/ContentViewProject";
import ReportActive from "assets/images/report-active.svg";

function ViewProject(props) {
  return (
    <div className="wrapper">
      <Header />
      <Sidebar status="Report" />

      <ContentViewProject />
    </div>
  );
}

export default ViewProject;
