import Notification from "assets/images/notification-active.svg";
import Search from "assets/images/search.svg";
import React, { Component } from "react";
import DataNotifications from "./Component/DataNotifications";

class ContentNotifications extends Component {
  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="page-header">
            <img src={Notification} alt="" />
            <h5>Notifikasi</h5>
          </div>
        </section>

        {/* Section Content */}
        <section className="content">
          <div className="content-main">
            <div className="wrapper-title">
              <div className="img-wrapper">
                <img src={Notification} alt="" />
              </div>
              <h5 className="content-title">Notifikasi</h5>
            </div>

            <div className="wrapper-search">
              <img className="search-icon" src={Search} alt="" />
              <input
                type="text"
                placeholder="Search by ID, Username, HP, or Status... "
                className="search-input"
              />
            </div>

            <div className="table-notif table-responsive-sm table-responsive-md">
              <table class="table ">
                <thead class="thead-green">
                  <tr>
                    <th scope="col">No.</th>
                    <th scope="col">ID Notifikasi</th>
                    <th scope="col">Tangggal Buat</th>
                    <th scope="col">Judul Notifikasi</th>
                    <th scope="col">Isi Notifikasi</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <DataNotifications
                  no="1"
                  id_notifikasi="DNO001"
                  tanggal_notifikasi="2019-02-10"
                  judul_notifikasi="Peringataan Kepada Perusahaan Makmur..."
                  isi_notifikasi="Untuk membayar tagihan bulan Desember 2020 dikarenakan..."
                />
                <DataNotifications
                  no="2"
                  id_notifikasi="DNO001"
                  tanggal_notifikasi="2019-02-10"
                  judul_notifikasi="Peringataan Kepada Perusahaan Makmur..."
                  isi_notifikasi="Untuk membayar tagihan bulan Desember 2020 dikarenakan..."
                />
                <DataNotifications
                  no="3"
                  id_notifikasi="DNO001"
                  tanggal_notifikasi="2019-02-10"
                  judul_notifikasi="Peringataan Kepada Perusahaan Makmur..."
                  isi_notifikasi="Untuk membayar tagihan bulan Desember 2020 dikarenakan..."
                />
              </table>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ContentNotifications;
