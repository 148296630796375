import Notification from "assets/images/notification-active.svg";
import React from "react";
import CKEditor from "ckeditor4-react";
import Close from "assets/images/close.svg";

function sendNotification(props) {
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="page-header">
          <img src={Notification} alt="" />
          <h5>Notifikasi / Tambah Notifikasi</h5>
        </div>
      </section>

      {/* Section Content */}
      <section className="content">
        <div className="content-main">
          <div className="wrapper-title">
            <div className="img-wrapper">
              <img src={Notification} alt="" />
            </div>
            <h5 className="content-title">Kirim Notifikasi Ke Perusahaan</h5>
          </div>

          <div className="wrapper-notif">
            <div className="wrapper-radio">
              <div className="check-forms">
                <input
                  className="radio-button"
                  type="radio"
                  value="Semua Perusahaan"
                  name="select"
                />
                <label class="form-check-label" for="exampleRadios1">
                  Kirim Ke Semua
                </label>
              </div>

              <div className="check-forms">
                <input
                  className="radio-button"
                  type="radio"
                  value="Semua Perusahaan"
                  name="select"
                />
                <label class="form-check-label" for="exampleRadios1">
                  Beberapa Perusahaan
                </label>
              </div>
            </div>

            <div className="wrapper-input">
              <label for="kepada">Kepada</label>
              <input
                type="text"
                placeholder="Masukkan nama perusahaan "
                className="input"
                id="kepada"
              />
            </div>
            <div className="company-notif">
              <div className="content-notif">
                <h5>Omnidtech</h5>
                <img src={Close} alt="" onClick={() => console.log("Close")} />
              </div>
            </div>
            <div className="wrapper-input">
              <label for="kepada">Judul</label>
              <input
                type="text"
                placeholder="Masukkan judul notifikasi "
                className="input"
                id="kepada"
              />
            </div>

            <CKEditor
              onChange={(e) => console.log(e.editor.getData())}
              config={{
                toolbarLocation: "bottom",
                editorplaceholder: "Type your comment…",
              }}
            />

            <div className="button-notif">
              <button
                type="button"
                className="button btn-approve"
                style={{ marginRight: "15px" }}
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Kirim
              </button>

              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Batal
              </button>
            </div>
          </div>
        </div>
      </section>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="wrapper-modal">
                <div className="img-wrapper">
                  <img src={Notification} alt="aproval-img" />
                </div>
              </div>
              <h5 class="title-modal" id="exampleModalLabel">
                Mengirim Notifikasi
              </h5>
            </div>
            <div class="body-modal">
              Apakah Anda ingin mengirim notifikasi ke{" "}
              <span className="company-name">PT Omind Muda Berkarya</span>?
            </div>
            <div class="footer-modal">
              <button type="button" class="btn btn-primary">
                Ya
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default sendNotification;
