import axios from "axios";

const RootPath = "https://jsonplaceholder.typicode.com";

const Get = (path) => {
  const promise = new Promise((resolve, reject) => {
    axios.get(`${RootPath}/${path}`).then(
      (result) => {
        resolve(result.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
  return promise;
};

const getContentApproval = () => Get("comments");
const getContentReport = () => Get("comments");
const getContentProject = () => Get("comments");

const API = {
  getContentApproval,
  getContentReport,
  getContentProject,
};

export default API;
