import "jquery";
import Home from "pages/ApprovalPage";
import DashboardReport from "pages/DashboardProject";
import Login from "pages/LoginPage";
import Notification from "pages/NotificationPage";
import SendNotification from "pages/SendNotificationPage";
import ComplainPages from "pages/ComplainPages";
import ChooseProject from "pages/ViewProject";
import Report from "pages/ViewReport";
import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Route exact path="/login" component={Login} />
      <Route exact path="/" component={Home} />
      <Route exact path="/notification" component={Notification} />
      <Route exact path="/sendNotification" component={SendNotification} />
      <Route exact path="/report" component={Report} />
      <Route exact path="/report/:id" component={ChooseProject} />
      <Route exact path="/dashboardReport/:id" component={DashboardReport} />
      <Route exact path="/complainPage/:id" component={ComplainPages} />
    </Router>
  );
}

export default App;
