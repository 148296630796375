import React from "react";
import ContentComplain from "parts/ContentComplain";
import Header from "parts/Header";
import Sidebar from "parts/Sidebar";

function ComplainPages(props) {
  return (
    <div className="wrapper">
      <Header />
      <Sidebar status="Report" />
      <ContentComplain />
    </div>
  );
}

export default ComplainPages;
