import React from "react";
import Report from "assets/images/report-active.svg";
import ICBack from "assets/images/ic_back.svg";
import { useHistory } from "react-router-dom";
import Person from "assets/images/person-complain.svg";
import { Dummy1, Dummy2 } from "assets";

function ContentComplain(props) {
  const history = useHistory();

  return (
    <div className="content-wrapper">
      {/* Section Content */}
      <section className="content">
        <div className="content-main">
          <div className="wrapper-title">
            <div className="img-wrapper">
              <img src={Report} alt="" />
            </div>
            <h5 className="content-title">Complain</h5>
          </div>

          <button
            className="wrapper-navigation-back"
            onClick={() => history.goBack()}
          >
            <img src={ICBack} alt="" />
            <h5 className="content-title">Kembali</h5>
          </button>

          {/* Content Wrapper */}
          <div className="section-content">
            <select className="select-option" name="" id="">
              <option value="">Kavling 1A</option>
            </select>

            <div className="wrapper-detail-complain">
              <div className="wrapper-complain-status">
                <div className="wrapper-status">
                  <h4>Kavling A2</h4>
                  <div className="status">Awas</div>
                </div>
                <div className="wrapper-progress-bar">
                  <div className="progress-bar-border">
                    <div
                      className={`progress-bar`}
                      style={{ width: `${"50%"}` }}
                    ></div>
                  </div>
                  <p>{"20%"}</p>
                </div>
              </div>

              <div className="wrapper-problem">
                <div className="wrapper-status-problem">
                  <h4>“Dinding Retak”</h4>
                  <div className="status">Marah Banget</div>
                  <div className="select-wrapper">
                    <select name="" id="">
                      <option value="">On Going</option>
                    </select>
                  </div>
                </div>
                <div className="person">
                  <img src={Person} alt="alt-img" />
                  <p>Fakhran Aidil</p>
                </div>
              </div>

              <div className="wrapper-image-problem">
                <img src={Dummy1} alt="alt-image" className="image-complain" />
                <img src={Dummy2} alt="alt-image" className="image-complain" />
              </div>
            </div>

            <div className="wrapper-detail-complain">
              <div className="wrapper-complain-status">
                <div className="wrapper-status">
                  <h4>Kavling A2</h4>
                  <div className="status">Awas</div>
                </div>
                <div className="wrapper-progress-bar">
                  <div className="progress-bar-border">
                    <div
                      className={`progress-bar`}
                      style={{ width: `${"50%"}` }}
                    ></div>
                  </div>
                  <p>{"20%"}</p>
                </div>
              </div>

              <div className="wrapper-problem">
                <div className="wrapper-status-problem">
                  <h4>“Dinding Retak”</h4>
                  <div className="status">Marah Banget</div>
                  <div className="select-wrapper">
                    <select name="" id="">
                      <option value="">On Going</option>
                    </select>
                  </div>
                </div>
                <div className="person">
                  <img src={Person} alt="alt-img" />
                  <p>Fakhran Aidil</p>
                </div>
              </div>

              <div className="wrapper-image-problem">
                <img src={Dummy1} alt="alt-image" className="image-complain" />
                <img src={Dummy2} alt="alt-image" className="image-complain" />
              </div>
            </div>

            <div className="wrapper-detail-complain">
              <div className="wrapper-complain-status">
                <div className="wrapper-status">
                  <h4>Kavling A2</h4>
                  <div className="status">Awas</div>
                </div>
                <div className="wrapper-progress-bar">
                  <div className="progress-bar-border">
                    <div
                      className={`progress-bar`}
                      style={{ width: `${"50%"}` }}
                    ></div>
                  </div>
                  <p>{"20%"}</p>
                </div>
              </div>

              <div className="wrapper-problem">
                <div className="wrapper-status-problem">
                  <h4>“Dinding Retak”</h4>
                  <div className="status">Marah Banget</div>
                  <div className="select-wrapper">
                    <select name="" id="">
                      <option value="">On Going</option>
                    </select>
                  </div>
                </div>
                <div className="person">
                  <img src={Person} alt="alt-img" />
                  <p>Fakhran Aidil</p>
                </div>
              </div>

              <div className="wrapper-image-problem">
                <img src={Dummy1} alt="alt-image" className="image-complain" />
                <img src={Dummy2} alt="alt-image" className="image-complain" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContentComplain;
