import ApprovalActive from "assets/images/approval-active.svg";
import Approval from "assets/images/approval.svg";
import NotificationActive from "assets/images/notification-active.svg";
import Notification from "assets/images/notification.svg";
import Report from "assets/images/report.svg";
import ReportActive from "assets/images/report-active.svg";
import React from "react";
import { Link } from "react-router-dom";

function Sidebar(props) {
  const statusActive = props.status;
  return (
    <aside className="main-sidebar">
      <section className="sidebar">
        <ul className="sidebar-menu" data-widget="tree">
          <li className={statusActive === "Report" && "active"}>
            <Link to="/report">
              <img
                src={statusActive === "Report" ? ReportActive : Report}
                alt=""
              />{" "}
              <span>View Report</span>
            </Link>
          </li>
          <li className={statusActive === "Approval" && "active"}>
            <Link to="/">
              <img
                src={statusActive === "Approval" ? ApprovalActive : Approval}
                alt=""
              />{" "}
              <span>Approval Perusahaan</span>
            </Link>
          </li>
          <li className={statusActive === "Notif" && "active"}>
            <Link to="/notification">
              <img
                src={
                  statusActive === "Notif" ? NotificationActive : Notification
                }
                alt=""
              />{" "}
              <span>Notifikasi</span>
            </Link>
          </li>
        </ul>
      </section>
    </aside>
  );
}

export default Sidebar;
