import React from "react";
import Background from "assets/images/background.png";
import Logo from "assets/images/logo.png";
import Button from "elements/Button";

function LoginBanner(props) {
  const login = () => {};
  return (
    <section className="banner">
      <figure>
        <img
          style={{ width: "100%" }}
          className="banner"
          src={Background}
          alt=""
        />
      </figure>

      <div className="meta-wrapper">
        <img className="logo" src={Logo} alt="" />
        <h2 className="welcome-text">Welcome to Promit!</h2>
        <h4 className="desc-text">Start manage your projects</h4>

        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Login</h5>
            <div className="label-form">
              <label className="label">Email</label>
              <input className="input-form" type="email" placeholder="Email" />
            </div>

            <div className="label-form">
              <label className="label">Password</label>
              <input
                className="input-form"
                type="password"
                placeholder="Password"
              />
            </div>
            <Button href="/" className="btn btn-login">
              Masuk
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginBanner;
