import ContentViewReport from "parts/ContentViewReport";
import Header from "parts/Header";
import Sidebar from "parts/Sidebar";
import React from "react";

function ViewReport(props) {
  return (
    <div className="wrapper">
      <Header />
      <Sidebar status="Report" />
      <ContentViewReport />
    </div>
  );
}

export default ViewReport;
