import React from "react";
import Banner from "parts/LoginBanner";
import Footer from "parts/Footer";

function LoginPage() {
  return (
    <div>
      <Banner />
      <Footer />
    </div>
  );
}

export default LoginPage;
