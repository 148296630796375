import React, { useState, Component } from "react";
import ReportActive from "assets/images/report-active.svg";
import Search from "assets/images/search.svg";
import Company from "assets/images/company.svg";
import Location from "assets/images/location.svg";
import Person from "assets/images/person.svg";
import Chart from "assets/images/chart.svg";
import axios from "axios";
import API from "../services/index";

import CardViewReport from "./Component/CardViewReport";

class ContentViewReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: [],
    };
  }

  getContentReport = () => {
    API.getContentReport().then((result) => {
      this.setState({
        report: result,
      });
    });
  };

  componentDidMount() {
    this.getContentReport();
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="page-header">
            <img src={ReportActive} alt="" />
            <h5>View Report</h5>
          </div>
        </section>

        {/* Section Content */}
        <section className="content">
          <div className="content-main">
            <div className="wrapper-title">
              <div className="img-wrapper">
                <img src={ReportActive} alt="" />
              </div>
              <h5 className="content-title">View Report</h5>
            </div>

            <div className="wrapper-search">
              <img className="search-icon" src={Search} alt="" />
              <input
                type="text"
                placeholder="Search by ID, Username, HP, or Status... "
                className="search-input"
              />
            </div>

            {this.state.report.map((item) => {
              return (
                <CardViewReport
                  company_name={item.name}
                  nib1="126382913241"
                  nib2="126382913242"
                  address="Jl. Matraman Raya No.46, RT.12/RW.2, Kb. Manggis, Kec.
        Matraman, Kota Jakarta Timur, Daerah Khusus Ibukota
        Jakarta 13151"
                  no_kemenkumham="372233288231"
                  direktur="Angga Nugraha"
                  komisaris="Fithor Muhammad"
                  project="2"
                />
              );
            })}
          </div>
        </section>
      </div>
    );
  }
}

export default ContentViewReport;
