import React, { createRef, useEffect, useState } from "react";
import TimeLine from "react-gantt-timeline";

function Perizinan(props) {
  let startDate = new Date();
  let finishDate = new Date();
  finishDate.setDate(finishDate.getDate() + 10);
  let totalDate = (finishDate - startDate) / (1000 * 3600 * 24);
  let content = [];
  const [array, setArray] = useState([]);
  const data = [
    {
      id: 1,
      title: "SPH Prioritas Tahan Makam",
      startDate: startDate,
      finishDate: startDate.getDate() + 12,
    },
    {
      id: 2,
      title: "SPH Prioritas Tahan Makam",
      startDate: startDate,
      finishDate: startDate.getDate() + 12,
    },
    {
      id: 3,
      title: "SPH Prioritas Tahan Makam",
      startDate: startDate,
      finishDate: startDate.getDate() + 12,
    },
    {
      id: 4,
      title: "SPH Prioritas Tahan Makam",
      startDate: startDate,
      finishDate: startDate.getDate() + 12,
    },
    {
      id: 5,
      title: "SPH Prioritas Tahan Makam",
      startDate: startDate,
      finishDate: startDate.getDate() + 12,
    },
  ];

  // Scroll
  const ref = createRef();

  const arrayDate = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Agus",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ];
    for (let i = 0; i <= totalDate; i++) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      const formatDate = date.getDate() + " " + months[date.getMonth()];
      content.push(formatDate);
    }

    setArray(content);
  };

  useEffect(() => {
    arrayDate();
  }, [1]);
  return (
    <>
      <div className="wrapper-item">
        {console.log(array.length)}
        <div className="wrapper-gantt-chart">
          {/* Jenis Perizinan */}
          <div className="project-perizinan-wrapper">
            <div className="wrapper-perizinan">
              <div className="wrapper-jenis">
                <h2>Jenis Perizinan</h2>
              </div>
              <div className="wrapper-detail-perizinan">
                {data.map((item, index) => {
                  return (
                    <>
                      {/* Title */}
                      <div className="wrapper-title">
                        <p>{item.title}</p>
                        <div
                          className="badge"
                          style={{
                            backgroundColor: `rgba(235, 87, 87, 0.12)`,
                            color: `#EB5757`,
                          }}
                        >
                          Awas
                        </div>
                      </div>

                      {/* Progress Bar */}
                      <div className="wrapper-progress-bar">
                        <div className="progress-bar-border">
                          <div
                            className={`progress-bar`}
                            style={{ width: `${"20%"}` }}
                          ></div>
                        </div>
                        <p>{"20%"}</p>
                      </div>

                      {/* Date */}
                      <div className="detail-progress">
                        <p>30 Des 2020</p>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>

          {/* Line Vertical */}
          <div className="line-vertical"></div>

          {/* Gantt Chart */}
          <div class="chart">
            <div
              className="chart-period"
              style={{
                gridTemplateColumns: `91px repeat(${array.length}, 1fr)`,
                // gridTemplateColumns: `91px repeat(20, 1fr)`,
              }}
            >
              {array.map((item, index) => {
                return <span>{item.toString()}</span>;
              })}
            </div>

            <div
              class="chart-lines"
              style={{
                gridTemplateColumns: `91px repeat(${array.length}, 1fr)`,
              }}
            >
              {array.map((item, index) => {
                return <span></span>;
              })}
            </div>

            {data.map((item, index) => {
              return (
                <ul
                  key={`id-${item}`}
                  class="chart-row-bars"
                  style={{
                    gridTemplateColumns: `91px repeat(${array.length}, 1fr)`,
                  }}
                >
                  <li class="chart-li" style={{ gridColumn: `1/4` }}>
                    {item.title}
                  </li>
                </ul>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Perizinan;
