import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Styling
import "assets/styling/scss/style.css";
import "assets/styling/bower_components/bootstrap/dist/css/bootstrap.min.css";
import "assets/styling/bower_components/font-awesome/css/font-awesome.min.css";
import "assets/styling/bower_components/Ionicons/css/ionicons.min.css";
import "assets/styling/dist/css/AdminLTE.css";
import "assets/styling/dist/css/skins/_all-skins.css";
import "assets/styling/bower_components/morris.js/morris.css";
import "assets/styling/bower_components/jvectormap/jquery-jvectormap.css";
import "assets/styling/bower_components/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
import "assets/styling/bower_components/bootstrap-daterangepicker/daterangepicker.css";
import "assets/styling/plugins/bootstrap-wysihtml5/bootstrap3-wysihtml5.min.css";
// import "bootstrap/scss/bootstrap.scss";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
