import Header from "parts/Header";
import Sidebar from "parts/Sidebar";
import React from "react";
import SendNotif from "parts/SendNotification";

function SendNotificationPage(props) {
  return (
    <div className="wrapper">
      <Header />
      <Sidebar status="Notif" />
      <SendNotif />
    </div>
  );
}

export default SendNotificationPage;
