import { FileCheck } from "assets";
import React from "react";

function Penjualan(props) {
  return (
    <>
      <div className="card-penjualan">
        <div className="wrapper-title">
          <div className="wrapper-img">
            <img src={FileCheck} alt="" />
          </div>
          <div>
            <h5>Nama penjualan Project</h5>
            <div className="wrapper-location">
              <h4>Green Resort Bali</h4>
              <h4>Farel Elsarawi</h4>
              <h4>
                <span>Desember - September</span>
              </h4>
            </div>
          </div>
        </div>

        <table>
          <tr className="table-title">
            <th>Kriteria Performa</th>
            <th className="title">Week 1</th>
            <th className="title">Week 2</th>
            <th className="title">Week 3</th>
            <th className="title">Week 4</th>
          </tr>

          <tr>
            <td></td>
            <td>
              <div className="wrapper-construction">
                <div className="wrapper-plan">Plan</div>
                <div className="wrapper-realisasi">Realisasi</div>
              </div>
            </td>
            <td>
              <div className="wrapper-construction">
                <div className="wrapper-plan">Plan</div>
                <div className="wrapper-realisasi">Realisasi</div>
              </div>
            </td>
            <td>
              <div className="wrapper-construction">
                <div className="wrapper-plan">Plan</div>
                <div className="wrapper-realisasi">Realisasi</div>
              </div>
            </td>
            <td>
              <div className="wrapper-construction">
                <div className="wrapper-plan">Plan</div>
                <div className="wrapper-realisasi">Realisasi</div>
              </div>
            </td>
          </tr>

          {/* Leads */}
          <tr>
            <td>
              <h5>Leads</h5>
            </td>
            <td>
              <div className="wrapper-total">
                <h4 className="plan-total">23</h4>
                <h4 className="realisasi-total" style={{ color: "#3C8B43" }}>
                  21
                </h4>
              </div>
            </td>
            <td>
              <div className="wrapper-total">
                <h4 className="plan-total">23</h4>
                <h4 className="realisasi-total" style={{ color: "#3C8B43" }}>
                  21
                </h4>
              </div>
            </td>
            <td>
              <div className="wrapper-total">
                <h4 className="plan-total">23</h4>
                <h4 className="realisasi-total" style={{ color: "#3C8B43" }}>
                  21
                </h4>
              </div>
            </td>
            <td>
              <div className="wrapper-total">
                <h4 className="plan-total">23</h4>
                <h4 className="realisasi-total" style={{ color: "#3C8B43" }}>
                  21
                </h4>
              </div>
            </td>
          </tr>

          {/* Survey */}
          <tr>
            <td>
              <h5>Survey</h5>
            </td>
            <td>
              <div className="wrapper-total">
                <h4 className="plan-total">23</h4>
                <h4 className="realisasi-total" style={{ color: "#3C8B43" }}>
                  21
                </h4>
              </div>
            </td>
            <td>
              <div className="wrapper-total">
                <h4 className="plan-total">23</h4>
                <h4 className="realisasi-total" style={{ color: "#3C8B43" }}>
                  21
                </h4>
              </div>
            </td>
            <td>
              <div className="wrapper-total">
                <h4 className="plan-total">23</h4>
                <h4 className="realisasi-total" style={{ color: "#3C8B43" }}>
                  21
                </h4>
              </div>
            </td>
            <td>
              <div className="wrapper-total">
                <h4 className="plan-total">23</h4>
                <h4 className="realisasi-total" style={{ color: "#3C8B43" }}>
                  21
                </h4>
              </div>
            </td>
          </tr>

          {/* Booking */}
          <tr>
            <td>
              <h5>Booking</h5>
            </td>
            <td>
              <div className="wrapper-total">
                <h4 className="plan-total">23</h4>
                <h4 className="realisasi-total" style={{ color: "#3C8B43" }}>
                  21
                </h4>
              </div>
            </td>
            <td>
              <div className="wrapper-total">
                <h4 className="plan-total">23</h4>
                <h4 className="realisasi-total" style={{ color: "#3C8B43" }}>
                  21
                </h4>
              </div>
            </td>
            <td>
              <div className="wrapper-total">
                <h4 className="plan-total">23</h4>
                <h4 className="realisasi-total" style={{ color: "#3C8B43" }}>
                  21
                </h4>
              </div>
            </td>
            <td>
              <div className="wrapper-total">
                <h4 className="plan-total">23</h4>
                <h4 className="realisasi-total" style={{ color: "#3C8B43" }}>
                  21
                </h4>
              </div>
            </td>
          </tr>

          {/* Akad */}
          <tr>
            <td>
              <h5>Akad</h5>
            </td>
            <td>
              <div className="wrapper-total">
                <h4 className="plan-total">23</h4>
                <h4 className="realisasi-total" style={{ color: "#3C8B43" }}>
                  21
                </h4>
              </div>
            </td>
            <td>
              <div className="wrapper-total">
                <h4 className="plan-total">23</h4>
                <h4 className="realisasi-total" style={{ color: "#3C8B43" }}>
                  21
                </h4>
              </div>
            </td>
            <td>
              <div className="wrapper-total">
                <h4 className="plan-total">23</h4>
                <h4 className="realisasi-total" style={{ color: "#3C8B43" }}>
                  21
                </h4>
              </div>
            </td>
            <td>
              <div className="wrapper-total">
                <h4 className="plan-total">23</h4>
                <h4 className="realisasi-total" style={{ color: "#3C8B43" }}>
                  21
                </h4>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </>
  );
}

export default Penjualan;
