import React from "react";
import BigLogo from "assets/images/big_logo.png";
import Logo from "assets/images/logo.png";
import Toogle from "assets/images/toogle.svg";

function Header(props) {
  return (
    <header className="main-header">
      <a href="index2.html" className="logo">
        <span className="logo-mini">
          <img src={Logo} alt="" />
        </span>
        <span className="logo-lg">
          <img src={BigLogo} alt="" />
        </span>
      </a>
      <nav className="navbar navbar-static-top">
        <a className="sidebar-toggle" data-toggle="push-menu">
          <img src={Toogle} alt="" />
        </a>

        <div className="navbar-custom-menu"></div>
      </nav>
    </header>
  );
}

export default Header;
