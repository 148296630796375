import ContentNotifications from "parts/ContentNotifications";
import Header from "parts/Header";
import Sidebar from "parts/Sidebar";
import React from "react";

function NotificationPage(props) {
  return (
    <div className="wrapper">
      <Header />
      <Sidebar status="Notif" />
      <ContentNotifications />
    </div>
  );
}

export default NotificationPage;
