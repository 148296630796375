import ContentApproval from "parts/ContentApproval";
import Header from "parts/Header";
import Sidebar from "parts/Sidebar";
import React from "react";

function ApprovalPage(props) {
  return (
    <div className="wrapper">
      <Header />
      <Sidebar status="Approval" />
      <ContentApproval />
    </div>
  );
}

export default ApprovalPage;
