import React, { useState, Component } from "react";
import Company from "assets/images/company.svg";
import Location from "assets/images/location.svg";
import Person from "assets/images/person.svg";
import Chart from "assets/images/chart.svg";
import { Link } from "react-router-dom";

class CardViewReport extends Component {
  render() {
    const {
      company_name,
      nib1,
      nib2,
      address,
      no_kemenkumham,
      direktur,
      komisaris,
      project,
    } = this.props;
    return (
      <div className="wrapper-approval">
        <div className="card-approval">
          <div className="company-details">
            <div className="img-wrapper">
              <img src={Company} alt="" />
            </div>
            <div className="wrapper-details-report">
              <p className="company-name">{company_name}</p>
              <div className="wrapper-nomor">
                <h5 className="text-nomor">NIB : {nib1}</h5>
              </div>

              <div className="wrapper-nomor">
                <h5 className="text-nomor">NIB : {nib2}</h5>
              </div>
            </div>
            <div className="button-wrapper">
              <Link to="/report/id=1233">
                <button
                  type="button"
                  className="button btn-approve"
                  style={{ marginRight: "15px" }}
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  View Project
                </button>
              </Link>
            </div>
          </div>
          <div className="detail-report">
            {/* Address */}
            <img src={Location} alt="" />
            <div className="address-detail">
              <p className="title">Alamat</p>
              <p className="detail-content">{address}</p>
            </div>

            {/* Kemenkumham Number */}
            <img src={Chart} alt="" />
            <div className="another-detail">
              <p className="title">No. Kemenkumham</p>
              <p className="detail-content">{no_kemenkumham}</p>
            </div>

            {/* Direktur */}
            <img src={Person} alt="" />
            <div className="another-detail">
              <p className="title">Direktur</p>
              <p className="detail-content">{direktur}</p>
            </div>

            {/* Komisaris */}
            <img src={Person} alt="" />
            <div className="another-detail">
              <p className="title">Komisaris</p>
              <p className="detail-content">{komisaris}</p>
            </div>

            {/* Project */}
            <img src={Person} alt="" />
            <div className="another-detail">
              <p className="title">Project</p>
              <p className="detail-content">{project}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardViewReport;
