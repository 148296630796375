import React, { Component } from "react";
import Details from "assets/images/details.svg";
import Delete from "assets/images/delete.svg";

class DataNotifications extends Component {
  render() {
    const {
      no,
      id_notifikasi,
      tanggal_notifikasi,
      judul_notifikasi,
      isi_notifikasi,
    } = this.props;
    return (
      <tbody>
        <tr>
          <td>{no}</td>
          <td>{id_notifikasi}</td>
          <td>{tanggal_notifikasi}</td>
          <td>{judul_notifikasi}</td>
          <td>{isi_notifikasi}</td>
          <td className="icon">
            <div className="wrapper-details">
              <a href={`/sendNotification`}>
                <img src={Details} alt="" />
              </a>
            </div>
            <div className="wrapper-delete">
              <img src={Delete} alt="" />
            </div>
          </td>
        </tr>
      </tbody>
    );
  }
}

export default DataNotifications;
