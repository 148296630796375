import ContentViewDashboard from "parts/ContentDashboard";
import Header from "parts/Header";
import Sidebar from "parts/Sidebar";
import React from "react";

function DashboardProject(props) {
  return (
    <div className="wrapper">
      <Header />
      <Sidebar status="Report" />
      <ContentViewDashboard />
    </div>
  );
}

export default DashboardProject;
