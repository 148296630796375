import { ReportActive } from "assets/images";
import React from "react";
import Warning from "assets/images/warning.svg";
import ModalComplain from "elements/Modal";
import { Dummy1, Dummy2 } from "assets";
import Person from "assets/images/person-complain.svg";
import { useState } from "react";

function Pembangunan(props) {
  const [modal, setModal] = useState(false);

  return (
    <>
      <div className="card-pembangunan">
        <div className="header-card">
          <div className="wrapper-pembangunan">
            <div className="wrapper-img">
              <img src={ReportActive} alt="" />
            </div>
            <div className="wrapper-detail">
              <h4>Plan Kontruksi</h4>
              <h5>
                Green Resort Bali<span>Farel Elsarawi</span>
              </h5>
            </div>
            <a href={"/complainPage/id=1"}>
              <button className="secondary">History Complain</button>
            </a>
            <button className="primary" onClick={() => setModal(!modal)}>
              Customer Complain
            </button>
          </div>
        </div>

        <div className="body-card">
          <div className="row">
            <div className="col-lg-6 box-kontruksi">
              <h3>Konstruksi</h3>
              <div className="card-content">
                <div className="wrapper-status">
                  <h4>Kavling A1</h4>
                  <div className="status">Awas</div>
                </div>

                <div className="wrapper-progress-bar">
                  <div className="progress-bar-border">
                    <div
                      className={`progress-bar`}
                      style={{ width: `${"50%"}` }}
                    ></div>
                  </div>
                  <p>{"20%"}</p>
                </div>

                <div className="detail-progress">
                  <p>30 Des 2020</p>
                  <p>
                    Sisa Hari : <span>18</span>
                  </p>
                  <p>
                    Sisa Bobot : <span>30</span>
                  </p>
                </div>
              </div>

              <div className="card-content">
                <div className="wrapper-status">
                  <h4>Kavling A2</h4>
                  <div className="status">Awas</div>
                </div>

                <div className="wrapper-progress-bar">
                  <div className="progress-bar-border">
                    <div
                      className={`progress-bar`}
                      style={{ width: `${"50%"}` }}
                    ></div>
                  </div>
                  <p>{"20%"}</p>
                </div>

                <div className="detail-progress">
                  <p>30 Des 2020</p>
                  <p>
                    Sisa Hari : <span>18</span>
                  </p>
                  <p>
                    Sisa Bobot : <span>30</span>
                  </p>
                </div>
              </div>

              <div className="wrapper-warning-box">
                <img src={Warning} alt="" />
                <p>2 Complain Costumer</p>
              </div>
            </div>

            <div className="col-lg-6 box-infrastruktur">
              <h3>Infrastruktur</h3>

              <div className="card-content">
                <div className="wrapper-status">
                  <h4>Pekerjaan Persiapan</h4>
                  <div className="status">Belum Mulai</div>
                </div>

                <div className="wrapper-progress-bar">
                  <div className="progress-bar-border">
                    <div
                      className={`progress-bar`}
                      style={{ width: `${"50%"}` }}
                    ></div>
                  </div>
                  <p>{"20%"}</p>
                </div>

                <div className="detail-progress">
                  <p>30 Des 2020</p>
                  <p>
                    Sisa Hari : <span>18</span>
                  </p>
                  <p>
                    Sisa Bobot : <span>30</span>
                  </p>
                </div>
              </div>

              <div className="card-content">
                <div className="wrapper-status">
                  <h4>Pekerjaan Persiapan</h4>
                  <div className="status">Belum Mulai</div>
                </div>

                <div className="wrapper-progress-bar">
                  <div className="progress-bar-border">
                    <div
                      className={`progress-bar`}
                      style={{ width: `${"50%"}` }}
                    ></div>
                  </div>
                  <p>{"20%"}</p>
                </div>

                <div className="detail-progress">
                  <p>30 Des 2020</p>
                  <p>
                    Sisa Hari : <span>18</span>
                  </p>
                  <p>
                    Sisa Bobot : <span>30</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Complain */}
      {modal && (
        <div className="modal-card-complain">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="title-modal" id="exampleModalLabel">
                  Customer Complain
                </h5>
                <button
                  type="button"
                  class="close"
                  onClick={() => setModal(!modal)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="body-modal">
                <div className="wrapper-status">
                  <h4>Kavling A2</h4>
                  <div className="status">Awas</div>
                </div>

                <div className="wrapper-progress-bar">
                  <div className="progress-bar-border">
                    <div
                      className={`progress-bar`}
                      style={{ width: `${"50%"}` }}
                    ></div>
                  </div>
                  <p>{"20%"}</p>
                </div>

                <div className="detail-progress">
                  <p>30 Des 2020</p>
                  <p>
                    Sisa Hari : <span>18</span>
                  </p>
                  <p>
                    Sisa Bobot : <span>30</span>
                  </p>
                </div>
              </div>
              <div class="footer-modal">
                {/* List */}
                <div className="wrapper-complain">
                  <div className="wrapper-image">
                    <img src={Dummy1} alt="alt-img" />
                    <img src={Dummy2} alt="alt-img" />
                  </div>
                  <div className="wrapper-problem">
                    <h4>"Dinding Rusak"</h4>
                    <div
                      className="status"
                      style={{
                        color: "#EB5757",
                        backgroundColor: "rgba(235, 87, 87, 0.1)",
                      }}
                    >
                      Marah Banget
                    </div>
                  </div>
                  <div className="person">
                    <img src={Person} alt="alt-img" />
                    <p>Fakhran Aidil</p>
                  </div>
                </div>

                <div className="wrapper-complain">
                  <div className="wrapper-image">
                    <img src={Dummy1} alt="alt-img" />
                    <img src={Dummy2} alt="alt-img" />
                  </div>
                  <div className="wrapper-problem">
                    <h4>"Dinding Rusak"</h4>
                    <div
                      className="status"
                      style={{
                        color: "#EB5757",
                        backgroundColor: "rgba(235, 87, 87, 0.1)",
                      }}
                    >
                      Marah Banget
                    </div>
                  </div>
                  <div className="person">
                    <img src={Person} alt="alt-img" />
                    <p>Fakhran Aidil</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Pembangunan;
