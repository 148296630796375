import React, { useState, Component } from "react";
import Company from "assets/images/company-name.svg";
import Location from "assets/images/location.svg";
import Person from "assets/images/person.svg";
import Chart from "assets/images/chart.svg";
import Unit from "assets/images/total_unit.svg";
import Value from "assets/images/project-value.svg";
import { Link } from "react-router-dom";

class CardViewProject extends Component {
  render() {
    const {
      company_name,
      address,
      luas_tanah,
      perusahaan,
      jumlah_unit,
      nilai_proyek,
      nama_pm,
    } = this.props;
    return (
      <div className="wrapper-approval">
        <div className="card-approval">
          <div className="company-details">
            <div className="img-wrapper">
              <img src={Company} alt="" />
            </div>
            <div className="wrapper-details-report">
              <p className="company-name">{company_name}</p>
            </div>
            <div className="button-wrapper">
              <Link to="/dashboardReport/id=1">
                <button
                  type="button"
                  className="button btn-approve"
                  style={{ marginRight: "15px" }}
                >
                  View Dashboard & Report
                </button>
              </Link>
            </div>
          </div>
          <div className="detail-project">
            {/* Address */}
            <img src={Location} alt="" />
            <div className="address-detail">
              <p className="title">Alamat</p>
              <p className="detail-content">{address}</p>
            </div>

            {/* Luas Tanah */}
            <img src={Chart} alt="" />
            <div className="another-detail">
              <p className="title">Luas Tanah</p>
              <p className="detail-content">{luas_tanah}m2</p>
            </div>

            {/* Company Name */}
            <img src={Company} alt="" />
            <div className="another-detail">
              <p className="title">Perusahaan</p>
              <p className="detail-content">{perusahaan}</p>
            </div>

            {/* Total Unit */}
            <img src={Unit} alt="" />
            <div className="another-detail">
              <p className="title">Jumlah Unit</p>
              <p className="detail-content">{jumlah_unit} Unit</p>
            </div>

            {/* Project Value */}
            <img src={Value} alt="" />
            <div className="another-detail">
              <p className="title">Nilai Proyek</p>
              <p className="detail-content">Rp {nilai_proyek}</p>
            </div>

            {/* PM Name */}
            <img src={Person} alt="" />
            <div className="another-detail">
              <p className="title">Nama PM</p>
              <p className="detail-content">{nama_pm}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardViewProject;
