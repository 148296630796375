import React from "react";

function Footer() {
  return (
    <footer
      style={{
        textAlign: "center",
        // position: "absolute",
        bottom: "2",
        marginTop: "2%",
        color: "grey",
      }}
    >
      <div className=" hidden-xs">
        Copyright &copy; 2021 Dana Syariah Indonesia. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;
