import React from "react";
import ProgressBar from "./Component/ProgressBar";

function Dashboard(props) {
  return (
    <section className="dashboard-section">
      <p className="date-report">
        <span>1 February 2021, </span>Thursday
      </p>

      {/* Fase Bar */}
      <div className="wrapper-fase">
        <h3 className="title-fase">Fase Pengondisian</h3>

        <div className="progress-wrapper">
          <div className="circle">
            <h5>Fase Persiapan</h5>
          </div>
          <div className="circle">
            <h5>Fase Pengondisian</h5>
          </div>
          <div className="circle active">
            <h5 className="active">Fase Penjualan</h5>
          </div>
          <div className="circle">
            <h5>Fase Hunian</h5>
          </div>
          <div className="circle">
            <h5>Fase Kepuasan</h5>
          </div>
        </div>
      </div>

      {/* Content Tab */}
      <div className="wrapper-content">
        <div className="row">
          <div className="col-md-6">
            <h5>Status Proyek</h5>
            <div className="card-status">
              <div className="box-logo">PT</div>

              <div className="wrapper-text">
                <p className="text-detail">SHGBM/SHM Pecah Kavling</p>
                <p className="text-time">
                  8 Hari Lagi Pada <span>12 Agustus 2021</span>
                </p>
              </div>

              <div className="wrapper-progress">
                <ProgressBar progress={60 / 100} />
              </div>
            </div>

            <div className="card-status">
              <div className="box-logo">PT</div>

              <div className="wrapper-text">
                <p className="text-detail">SHGBM/SHM Pecah Kavling</p>
                <p className="text-time">
                  8 Hari Lagi Pada <span>12 Agustus 2021</span>
                </p>
              </div>

              <div className="wrapper-progress">
                <ProgressBar progress={100 / 100} />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <h5>Realisasi Saat ini</h5>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Dashboard;
